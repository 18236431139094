import { Link } from 'gatsby'
import React from 'react'
import styles from './nav-link.module.scss'

const NavLink = props => {
	return (
		<Link
			className={styles.navLink}
			activeClassName={styles.navLink__active}
			{...props}
		/>
	)
}

export default NavLink
