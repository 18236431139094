import React from 'react'

type TProps = {
	imageUrl: string
	children: string | React.ReactElement
	idPowerUp: string;
}

const ImageWithDescription = (props: TProps) => {

	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'space-between',
				clear: 'both',
			}}
		>
			<div style={{ width: '48%' }}>
				<img style={{ width: '100%' }} src={props.imageUrl} />
			</div>
			<div style={{ width: '48%' }}>
				{props.children}
				<a href={`https://trello.com/power-ups/${props.idPowerUp}/enable`}>
					<img alt="Add to Trello" height="40" width="144" src="https://p.trellocdn.com/add_to_trello.png" srcSet="https://p.trellocdn.com/add_to_trello.png 1x, https://p.trellocdn.com/add_to_trello@2x.png 2x" />
				</a>
			</div>
			{/* <div style={{ width: '48%' }}>{remark2react(props.description)}</div> */}
		</div>
	)
}

export default ImageWithDescription
